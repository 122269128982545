@import "src/Component/mixin";
.product-group-modal_confirm{
    width : 480px;
    height: 196px;
    padding: 24px;
    .product-group-modal_txt{
        margin-top: 24px;
    }
    .product-group-modal_dismiss{
        button{
            width: 110px;
            height: 32px;
            padding: 0;
            font-size:14px !important;
        }
    }
}
.product-group-modal_right-side{
    .right-sight-popup__box--dismiss {
        width: 1.5rem;
        height: 1.5rem;
        animation: fadeInRight 0.3s ease-in-out;
        position: fixed;
        top: 0rem;
        right: 62.25rem;
        cursor: pointer;
        background: rgb(124, 142, 160);
        padding: 0.2rem 0.3rem;
    }
    .right-sight-popup__wrapper{
        animation: fadeInRight 0.3s ease-in-out;
        background: white;
        position: absolute;
        top: 0rem;
        right: 0;
        width: 62.25rem;
        height: 100vh;
        overflow-y: scroll;
        padding: 1.5rem;
        transition: 0.3s;
    }
    .right-sight-popup__footer{
        width: 62.25rem;
    }
}
@include desktop_large_max{
    .product-group-modal_confirm{
        width : 480px;
        height: 196px;
        padding: 24px;
        margin: 14rem auto 0 26rem;
    }
}
@include  desktop_width_1280{
    .product-group-modal_confirm{
        width : 480px;
        height: 196px;
        padding: 24px;
        margin: 14rem auto 0 28rem;
    }
}
.event-content{
    position: relative;
    margin-top: 8px;
    padding: 8px;
    max-height: 95px;
    &__icon{
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
    img{
        height: 100% !important;
    }
}
@media screen and (max-width: 1366px){
    .product-group-modal_right-side .right-sight-popup__wrapper {
        height: 93vh!important;
    }
}